import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { crossfaderAction } from './../../../core/redux/actions/mixer.actions';

@Component({
    selector: 'um-crossfader',
    templateUrl: './crossfader.component.html',
    styleUrls: ['./crossfader.component.scss'],
    standalone: false
})
export class CrossfaderComponent implements OnInit {
  private store = inject(Store);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit(): void {}

  handleVolumeChanged(value: number) {
    this.store.dispatch(crossfaderAction({ value }));
  }
}
