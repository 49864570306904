import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'volume',
    templateUrl: './volume.component.html',
    styleUrls: ['./volume.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class VolumeComponent implements OnInit {
  zone = inject(NgZone);
  private sanitizer = inject(DomSanitizer);

  @Input() levell1Value = 0;
  @Input() levell2Value = 0;
  @Input() playing = false;
  @Output() volume = new EventEmitter<number>();

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {

    // needed to control this widget from UltraMixer6
    window['ultramixer'] = {
      mixer: {
        setLevels: this.setLevels.bind(this),
      },
    };
  }

  setLevels(left: number, right: number) {
    this.zone.run(() => {
      console.log(this.levell1Value);
      this.levell1Value = left;
      this.levell2Value = right;
    });
  }

  handleVolumeChanged(value: string) {
    this.volume.emit(parseFloat(value));
  }
}
