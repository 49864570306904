import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getProgress } from '../../../core/redux/reducers/player.reducer';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: false
})
export class SearchComponent implements OnInit {
  private store = inject(Store);

  @Input('playerID') playerID: number;

  progress$: Observable<number>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.progress$ = this.store.select(getProgress(this.playerID)).pipe(map((value: number) => value * 100));
  }
}
