import { Component, Input, NgZone, OnInit, inject } from '@angular/core';

@Component({
    selector: 'turntable',
    templateUrl: './turntable.component.html',
    styleUrls: ['./turntable.component.scss'],
    standalone: false
})
export class TurntableComponent implements OnInit {
    private zone = inject(NgZone);


    @Input("playing") playing: boolean = false;

    /** Inserted by Angular inject() migration for backwards compatibility */
    constructor(...args: unknown[]);

    constructor() {
    }

    ngOnInit() {

        window["ultramixer"] = {
            turntable: {
                setPlaying: this.setPlaying.bind(this)
            }
        };
    }

    setPlaying(playing: boolean) {
        this.zone.run(() => {
            this.playing = playing;
        });
    }

}
