import { Component, Input, OnInit, inject } from '@angular/core';
import { loadSampleBankAction, playSampleAction } from '../../../core/redux/actions/audiosampler.actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'audio-sampler',
    templateUrl: './audio-sampler.component.html',
    styleUrls: ['./audio-sampler.component.scss'],
    standalone: false
})
export class AudioSamplerComponent implements OnInit {
  private store = inject(Store);

  @Input('noSlots') noSlots: number = 4;

  slots: Array<any>;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {
    this.slots = new Array(this.noSlots);
    this.store.dispatch(loadSampleBankAction());
  }

  handleClick(slotNumber: number) {
    this.store.dispatch(playSampleAction({ slotIndex: slotNumber }));
  }
}
