import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setWidgetAction } from '../../../core/redux/actions/general.actions';
import { getWidget1 } from '../../../core/redux/reducers/general.reducer';

@Component({
  selector: 'widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.scss'],
  standalone: false,
})
export class WidgetsComponent implements OnInit {
  private store = inject(Store);

  widget1$: Observable<string> = this.store.select(getWidget1);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {}

  ngOnInit() {}

  widgetChanged($event) {
    this.store.dispatch(setWidgetAction({ slotID: 0, widgetID: $event.detail.value }));
  }
}
